body,
html,
#__next {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  width: 100%;
  height: 100%;
}

img {
  max-width: 100%;
  display: block;
}

* {
  box-sizing: border-box;
}

/* website font Spoqa Han Sans Neo */
@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 700;
  src: local(""), url("/fonts/SpoqaHanSansNeoBold.woff2") format("woff2"),
    url("/fonts/SpoqaHanSansNeo-Bold.woff") format("woff"),
    url("/fonts/SpoqaHanSansNeo-Bold.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 400;
  src: local(""), url("/fonts/SpoqaHanSansNeoRegular.woff2") format("woff2"),
    url("/fonts/SpoqaHanSansNeo-Regular.woff") format("woff"),
    url("/fonts/SpoqaHanSansNeo-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 300;
  src: local(""), url("/fonts/SpoqaHanSansNeoLight.woff2") format("woff2"),
    url("/fonts/SpoqaHanSansNeo-Light.woff") format("woff"),
    url("/fonts/SpoqaHanSansNeo-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Spoqa Han Sans Neo";
  font-weight: 100;
  src: local(""), url("/fonts/SpoqaHanSansNeoThin.woff2") format("woff2"),
    url("/fonts/SpoqaHanSansNeo-Thin.woff") format("woff"),
    url("/fonts/SpoqaHanSansNeo-Thin.ttf") format("truetype");
}

/* inapp font Noto Sans */

/* noto-sans-kr-100 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 100;
  src: url("/fonts/noto-sans-kr-v13-latin_korean-100.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/noto-sans-kr-v13-latin_korean-100.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/noto-sans-kr-v13-latin_korean-100.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-100.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/noto-sans-kr-v13-latin_korean-100.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/noto-sans-kr-v13-latin_korean-100.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
/* noto-sans-kr-300 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/noto-sans-kr-v13-latin_korean-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/noto-sans-kr-v13-latin_korean-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/noto-sans-kr-v13-latin_korean-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-300.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/noto-sans-kr-v13-latin_korean-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/noto-sans-kr-v13-latin_korean-300.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
/* noto-sans-kr-regular - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/noto-sans-kr-v13-latin_korean-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/noto-sans-kr-v13-latin_korean-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/noto-sans-kr-v13-latin_korean-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-regular.woff") format("woff"),
    /* Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-regular.ttf") format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/noto-sans-kr-v13-latin_korean-regular.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
/* noto-sans-kr-700 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/noto-sans-kr-v13-latin_korean-700.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/noto-sans-kr-v13-latin_korean-700.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/noto-sans-kr-v13-latin_korean-700.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-700.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/noto-sans-kr-v13-latin_korean-700.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/noto-sans-kr-v13-latin_korean-700.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
/* noto-sans-kr-500 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 500;
  src: url("/fonts/noto-sans-kr-v13-latin_korean-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/noto-sans-kr-v13-latin_korean-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/noto-sans-kr-v13-latin_korean-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-500.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/noto-sans-kr-v13-latin_korean-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/noto-sans-kr-v13-latin_korean-500.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
/* noto-sans-kr-900 - latin_korean */
@font-face {
  font-family: "Noto Sans KR";
  font-style: normal;
  font-weight: 900;
  src: url("/fonts/noto-sans-kr-v13-latin_korean-900.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("/fonts/noto-sans-kr-v13-latin_korean-900.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/fonts/noto-sans-kr-v13-latin_korean-900.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/fonts/noto-sans-kr-v13-latin_korean-900.woff") format("woff"),
    /* Modern Browsers */ url("/fonts/noto-sans-kr-v13-latin_korean-900.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/fonts/noto-sans-kr-v13-latin_korean-900.svg#NotoSansKR")
      format("svg"); /* Legacy iOS */
}
